<template>
  <div>
    <header>
      <router-link to="/">
        <img class="logo" src="../assets/images/logo.png" />
      </router-link>
      <ul>
        <li>
          <router-link   v-if="$route.name != 'status'" to="/form">
            ACCÉDER AU FORMULAIRE
          </router-link>
        </li>
        <!-- <li><router-link to="/form">REMBOURSEMENT</router-link></li>    -->
        <!-- <li><a href="https://mon-installateur.atlantic.fr/" target="_blank">INSTALLATEURS</a>&nbsp;&nbsp;</li>              -->
      </ul>
    </header>
  </div>
</template>

<script>
export default {
  name: 'HeaderElem',
}
</script>
<style lang="css"></style>
