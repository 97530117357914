<template>
  <div class="home">
    <div class="wrapper-desk">
      <img class="bandeau desktop" src="../assets/images/bandeauUPD2.jpg" />
      <router-link tag="button" to="form">JE PARTICIPE</router-link>
      <div class="rectangle" />
      <a href="https://projet-gaz.grdf.fr/quinzaine-du-gaz" target="_blank" rel="noopener noreferer" class="clickable" />
      <p>Quinzaine du Gaz GRDF<br />du 13 au 28 mars 2021</p>
    </div>
    <div class="wrapper-mob">
      <img class="bandeau" src="../assets/images/bandeau-mobileUPD.jpg" />
      <router-link tag="button" to="form">JE PARTICIPE</router-link>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Home',
  mounted() {

  }
}
</script>
